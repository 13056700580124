<template>
  <v-row
    justify="center"
    class="stats-table-row my-4">
    <v-col
      cols="12"
      md="6">
      <v-card class="pa-4">
        <p>
          หน้าสำหรับแปลงข้อมูลจาก file ที่ได้จากเครื่อง Scan QR เป็นสรุปจำนวนตามสินค้า โดย Upload file ที่เป็น
          .csv ระบบจะทำการนับจำนวนสินค้าตามรหัส QR Code สามารถ Upload ได้มากกว่า 1 ไฟล์
        </p>
        <p>
          * เมื่อทำการ Upload ครบแล้วสามารถ Dowload ที่มุมขวาบนตารางผลลัพธ์ได้
        </p>
        <div>
          <img
            style="margin: auto;"
            width="70%"
            src="@/assets/image/correct-data.png"
            alt="">
        </div>
        <p class="text-center">
          แสดงตัวอย่างไฟล์ที่สามารถ Upload ได้
        </p>
      </v-card>
      <v-card class="mt-2 pa-4">
        <div class="d-flex">
          <v-file-input
            label="Select CSV file"
            accept=".csv"
            color="secondary"
            outlined
            dense
            hide-details
            :loading="loading"
            @change="fileHandler($event)" />
        </div>
        <div class="mt-4">
          <ul>
            <li
              v-for="(file, index) of files"
              :key="index">
              {{ file.name }}
              <a
                class="red--text"
                @click="files.splice(index, 1)">
                x
              </a>
            </li>
          </ul>
        </div>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="6">
      <v-card class="pa-4">
        <div class="d-flex justify-space-between">
          <span>
            Result
          </span>
          <v-icon
            color="#1B5E20"
            class="mr-2"
            @click="exportCsv()">
            mdi-microsoft-excel
          </v-icon>
        </div>
        <v-simple-table>
          <thead>
            <tr>
              <th>
                Code
              </th>
              <th>
                QTY
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in items"
              :key="item.code">
              <td>{{ item.code }}</td>
              <td>{{ item.qty }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ExportToCsv } from 'export-to-csv'
import { mapGetters } from 'vuex'

const fileReader = new FileReader()

export default {
  data () {
    return {
      loading: false,
      data: [],
      qr: null,
      files: []
    }
  },
  computed: {
    ...mapGetters({
      storeSetting: 'Store/storeSetting'
    }),
    items () {
      const results = []
      for (const file of this.files) {
        for (const item of file.items) {
          const index = results.findIndex((re) => re.code === item.code)
          if (index === -1) {
            results.push({
              code: item.code,
              qty: item.qty
            })
          } else {
            results[index].qty += item.qty
          }
        }
      }

      return results
    }
  },
  methods: {
    fileHandler (file) {
      if (file) {
        fileReader.readAsText(file, 'utf8')
        fileReader.onload = (readerEvent) => {
          const contents = readerEvent.target.result.split('\n')
          const raws = []
          contents.forEach((line) => {
            if (line.length > 0) {
              const text = line
                .replaceAll('"', '')
                .replaceAll('\r', '')
                .split(',')

              raws.push(text)
            }
          })

          const data = []
          for (const raw of raws) {
            if (raw[0] !== '') {
              const codes = raw[0].split(`${this.storeSetting.webUrl}/`)
              const code = codes[codes.length - 1]
              const index = data.findIndex((re) => re.code === code)
              if (index === -1) {
                data.push({
                  code,
                  qty: 1
                })
              } else {
                data[index].qty += 1
              }
            }
          }

          this.files.push({
            name: file.name,
            items: data
          })
        }
      }
    },
    exportCsv () {
      const options = {
        filename: 'group-items',
        showLabels: true,
        useKeysAsHeaders: true
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(this.items)
  }
  }
}
</script>
